"use client";

import { Fillern } from "../../../../components/errorPages/Fillern";
import { useReportError } from "../../../global-error";

type Props = {
  error: Error;
};

const SiteErrorPage = ({ error }: Props) => {
  useReportError(error);

  return <Fillern />;
};

export default SiteErrorPage;
