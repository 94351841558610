import { SVGProps } from "react";

export const ErrorPageFigure = (props: SVGProps<SVGSVGElement>) => (
  // Scale width and height to parent container
  <svg width="100%" height="100%" viewBox="0 0 64 64" {...props}>
    <g id="outline">
      <path d="M51,56H45a3,3,0,0,0,0,6h6a3,3,0,0,0,0-6Zm0,4H45a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Z" />
      <rect height="2" width="2" x="34" y="58" />
      <rect height="2" width="4" x="26" y="60" />
      <path d="M33,36a1,1,0,0,0-1,1v4a1,1,0,0,1-2,0V37a1,1,0,0,0-2,0v4a3,3,0,0,0,6,0V37A1,1,0,0,0,33,36Z" />
      <path d="M32.293,15.95,24.95,23.293a1,1,0,1,0,1.414,1.414l7.343-7.343a1,1,0,0,0-1.414-1.414Z" />
      <path d="M22.949,25.293l-2,2a1,1,0,1,0,1.414,1.414l2-2a1,1,0,0,0-1.414-1.414Z" />
      <path d="M17.949,30.293l-3.636,3.636a10.013,10.013,0,0,0,0,14.142,1,1,0,0,0,1.415-1.414,8.01,8.01,0,0,1,0-11.314l3.635-3.636a1,1,0,0,0-1.414-1.414Z" />
      <path d="M57.707,2.293a1,1,0,0,0-1.414,0L41,17.586l-7.293-7.293a1,1,0,0,0-1.414,0L11.485,31.1A14.019,14.019,0,0,0,10.66,50H5a3,3,0,0,0,0,6H41a3,3,0,0,0,0-6H35.414l4.611-4.611A3,3,0,0,0,46,45V39.414l2-2V43a3,3,0,0,0,6,0V31a1,1,0,0,0-.293-.707L46.414,23,61.707,7.707a1,1,0,0,0,0-1.414ZM52,31.414V43a1,1,0,0,1-2,0V35a1,1,0,0,0-1.707-.707l-4,4A1,1,0,0,0,44,39v6a1,1,0,0,1-2,0V43a1,1,0,0,0-1.707-.707l-8,8A1,1,0,0,0,33,52h8a1,1,0,0,1,0,2H5a1,1,0,0,1,0-2h8a1,1,0,0,0,.707-1.707l-.808-.808a12.014,12.014,0,0,1,0-16.97L33,12.414Zm-7-9.828L42.414,19,57,4.414,59.586,7Z" />
    </g>
  </svg>
);
