"use client";

import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import { Fillern } from "../components/errorPages/Fillern";

type Props = {
  error: Error;
};

const isSentryEnabled = !!process.env.NEXT_PUBLIC_SENTRY_DSN;

export const useReportError = (error: Error) => {
  useEffect(() => {
    // Log the error to Sentry
    isSentryEnabled && Sentry.captureException(error);
  }, [error]);
};

export default function ErrorPages({ error }: Props) {
  useReportError(error);
  return (
    <html>
      <body>
        <Fillern />
      </body>
    </html>
  );
}
