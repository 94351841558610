import NextLink from "next/link";
import { forwardRef } from "react";
import { useTranslation } from "../utils/hooks/useTranslation";

// eslint-disable-next-line react/display-name
export const LocalizedNextLink = forwardRef<HTMLAnchorElement, React.ComponentProps<typeof NextLink>>(
  ({ href, ...props }, ref) => {
    const { lang } = useTranslation();

    return <NextLink href={`/${lang}${href}`} {...props} ref={ref} />;
  }
);
