"use client";

import { Box, Button, Flex, Grid, Heading, Text, VStack } from "@biblioteksentralen/react";
import { ArrowLeft } from "react-feather";
import { DefaultContainer } from "../../components/ContentContainer";
import { ErrorPageFigure } from "../../components/errorPages/ErrorPageFigure";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { useCommonData } from "../layout/CommonDataProvider";
import { LocalizedNextLink } from "../LocalizedNextLink";

export const Fillern = () => {
  const { t } = useTranslation();

  const site = useCommonData().site;

  return (
    <DefaultContainer padding={{ base: "3rem 0 5rem", md: "5rem 0 7rem" }}>
      <Grid gridTemplateColumns={{ base: "1fr", sm: "1fr 1fr" }}>
        <Flex
          justifyContent={{ base: "center", md: "flex-end" }}
          alignItems="center"
          marginRight={{ base: undefined, md: "2rem" }}
          marginBottom={{ base: "2rem", sm: "0" }}
        >
          <Box width={{ base: "12rem", md: "16rem" }} height={{ base: "12rem", md: "16rem" }}>
            <ErrorPageFigure />
          </Box>
        </Flex>
        <VStack as="main" id="main" gap="1rem" alignItems="flex-start" padding={{ base: "0 1rem 0", sm: "0 3rem" }}>
          <Heading as="h1" size="3xl" marginTop="0 !important">
            {t("Filler'n!")}
          </Heading>
          <Heading as="h2" fontSize="2xl" marginTop="0 !important">
            {t("Dette var uventet")}
          </Heading>
          <Text marginTop="0 !important" fontSize="sm" maxWidth="17rem">
            {t("Noe har gått galt. Ha litt tålmodighet med oss, så løser det seg nok snart.")}
          </Text>
          <Text marginTop="0 !important" fontSize="sm">
            {`(${t("Feilkode 500")})`}
          </Text>
          {!!site && (
            <Button
              as={LocalizedNextLink}
              href="/"
              size="md"
              leftIcon={<ArrowLeft size="1em" />}
              marginTop={{ md: "1rem !important" }}
            >
              {t("Gå til forsiden")}
            </Button>
          )}
        </VStack>
      </Grid>
    </DefaultContainer>
  );
};
